import React, { useEffect, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import SEO from "../components/SEO";

const NotFoundPage = () => {
  const [isLoading, setLoading] = useState(true);
  const pages = useStaticQuery(
    graphql`
      query {
        allSitePage(
          filter: { context: { productPath: { regex: "/[redirect]/g" } } }
        ) {
          edges {
            node {
              id
              context {
                productId
              }
            }
          }
        }
      }
    `
  );

  useEffect(() => {
    const path = window.location.pathname.split("/");
    const queryId = path[path.length - 1];

    const redirectTo = pages?.allSitePage?.edges?.filter(
      (page) => page.node.context.productId === queryId
    );

    if (redirectTo?.length === 1) {
      const url = redirectTo[0].node.id.replace("SitePage ", "");
      window.location.replace(url);
    }

    setLoading(false);
  }, []);

  return (
    <>
      <SEO
        title="Siden blev ikke fundet"
        description="Øv! Siden blev ikke fundet"
      />
      <div className="h-64">
        {isLoading ? null : (
          <>
            <h2 className="text-3xl mb-5">Øv! Siden blev ikke fundet</h2>
            <Link className="underline" to="/">
              Gå til forside
            </Link>
          </>
        )}
      </div>
    </>
  );
};

export default NotFoundPage;
